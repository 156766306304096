import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dbd08ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "header__title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  class: "body",
  ref: "popupBody"
}
const _hoisted_6 = {
  key: 0,
  class: "thin-divider"
}
const _hoisted_7 = {
  key: 1,
  class: "footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: "popup-wrapper",
    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clickOutPopup && _ctx.clickOutPopup(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
        (_ctx.closeButton)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "cancel__icon",
              src: _ctx.closeIcon,
              alt: "close icon",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.hidePopup && _ctx.hidePopup(...args)), ["stop"])),
              onMousedown: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
            }, null, 40, _hoisted_4))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 512),
      (_ctx.isOverflowed && _ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6))
        : _createCommentVNode("", true),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 512)), [
    [_vShow, _ctx.popupKey ? _ctx.popupState[_ctx.popupKey] : true]
  ])
}
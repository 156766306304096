import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43888529"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup-input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('company.editConfirmButton'),
        class: "w-full",
        onClick: _ctx.submit_referral_company_name
      }, null, 8, ["text", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.labelPopup), 1),
      _createVNode(_component_Dropdown, {
        options: _ctx.list_referral_company,
        modelValue: _ctx.editValueId,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.editValueId) = $event)),
        "item-text": "name",
        "item-value": "id"
      }, null, 8, ["options", "modelValue"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c7c5bd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-popup"
}
const _hoisted_2 = { class: "customer-contact" }
const _hoisted_3 = { class: "contact-item mr-2" }
const _hoisted_4 = { class: "popup-input-label" }
const _hoisted_5 = { class: "contact-item mr-2" }
const _hoisted_6 = { class: "popup-input-label" }
const _hoisted_7 = { class: "w-full mt-5" }
const _hoisted_8 = {
  key: 1,
  class: "text-popup"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "w-full mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('company.customerContactTitle'),
    "popup-key": _ctx.popupKey
  }, {
    default: _withCtx(() => [
      (_ctx.isTransitionFromCompanyList)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_Transition, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("company.titleCustomerContactType")), 1),
                    _createVNode(_component_Dropdown, {
                      options: _ctx.contactOptions,
                      "item-text": "name",
                      "item-value": "id",
                      modelValue: _ctx.selectedContactType,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedContactType) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("company.titleCustomerContactNotification")), 1),
                    _createVNode(_component_Dropdown, {
                      options: _ctx.notificationOptions,
                      "item-text": "name",
                      "item-value": "id",
                      modelValue: _ctx.selectedNotification,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedNotification) = $event))
                    }, null, 8, ["options", "modelValue"])
                  ])
                ])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_FlatButton, {
                "on-click": _ctx.handleAction,
                text: _ctx.$t('common.confirm'),
                class: "mt-5 confirm-button"
              }, null, 8, ["on-click", "text"])
            ])
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("p", {
              innerHTML: _ctx.$t('company.titleCustomerContactConfirm', { contactTypeName: _ctx.contactTypeName, isSendEmail: _ctx.isSendEmail }),
              class: "message"
            }, null, 8, _hoisted_9),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_FlatButton, {
                "on-click": _ctx.handleAction,
                text: _ctx.$t('common.confirm'),
                class: "mt-5 confirm-button"
              }, null, 8, ["on-click", "text"])
            ])
          ]))
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
import { reactive } from "vue";

const state = {
  templateSignerAdd: false,
  adminManagement: false,
  updateText: false,
  updateName: false,
  updateLogo: false,
  updateImageFile: false,
  updateAddress: false,
  updateReferralCompany: false,
  updateRoleMember: false,
  updateRate: false,
  confirmAction: false,
  confirmSubmitEmail: false,
  changePlanCompany: false,
  changeCustomerContact: false,
  downgradeToFreePlan: false,
  showEmailPreview: false,
};
export type PopupKey = keyof typeof state;

type PopupControl = () => {
  popupState: typeof state;
  openPopup: (key: PopupKey) => void;
  closePopup: (key: PopupKey) => void;
};

const usePopupControl: PopupControl = () => {
  const popupState = reactive(state);

  const openPopup = (key: PopupKey) => {
    popupState[key] = true;
  };

  const closePopup = (key: PopupKey) => {
    popupState[key] = false;
  };

  return {
    popupState,
    openPopup,
    closePopup,
  };
};

export default usePopupControl;

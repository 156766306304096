
import { defineComponent, ref, PropType } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";

type Action = (lastName: string, firstName: string) => void;

export default defineComponent({
  name: "UpdateNamePopup",
  components: {
    Popup,
    FlatButton,
    ValidationTextField,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    lastName: {
      type: String,
      default: "",
    },
    firstName: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "変更する",
    },
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
  },
  emits: ["onUpdateNameDone"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateName";

    const localFirstName = ref(props.firstName);
    const localLastName = ref(props.lastName);

    const updateText = async () => {
      await props.action(localLastName.value, localFirstName.value);
      emit("onUpdateNameDone");
      closePopup(popupKey);
    };

    return {
      localFirstName,
      localLastName,
      popupKey,
      updateText,
    };
  },
});

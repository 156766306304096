import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-625a8019"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-title" }
const _hoisted_2 = { class: "info-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ItemInList = _resolveComponent("ItemInList")!

  return (_openBlock(), _createBlock(_component_ItemInList, { class: "item-edit-info" }, {
    head: _withCtx(() => [
      _renderSlot(_ctx.$slots, "name", {}, () => [
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.infoTitle), 1)
      ], true)
    ]),
    body: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, [
        _renderSlot(_ctx.$slots, "value", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.infoValue), 1)
        ], true)
      ])
    ]),
    tail: _withCtx(() => [
      _renderSlot(_ctx.$slots, "tail", {}, () => [
        _createVNode(_component_TextButton, {
          icon: require('icons/edit.svg'),
          text: _ctx.$t('cmind.optionEdit'),
          onOnClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('on-edit'))),
          enabled: !_ctx.disableAction,
          "disabled-icon": require('icons/edit-grey.svg')
        }, null, 8, ["icon", "text", "enabled", "disabled-icon"])
      ], true)
    ]),
    _: 3
  }))
}
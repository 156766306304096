
import { defineComponent, PropType, ref } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, { PopupKey } from "@/lib/compositional-logic/usePopupControl";
import { useI18n } from "vue-i18n";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import { ContactType, getContactTypeName } from "@/locales/ja/company";

type Action = (
  contactType: ContactType,
  sendEmail: boolean,
) => Promise<unknown>;

export default defineComponent({
  name: "ChangeCustomerContactPopup",
  components: {
    Popup,
    FlatButton,
    Dropdown,
  },
  props: {
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
    contactType: { type: Number },
    isSendEmail: { type: Boolean },
  },
  emits: ["change-customer-contact-done", "change-customer-contact-error"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "changeCustomerContact";
    const isTransitionFromCompanyList = props.contactType === undefined
    const contactTypeName = isTransitionFromCompanyList ? "" : getContactTypeName(props.contactType);

    const contactOptions = [
      { id: "0", name: t("company.CustomerSurveyName") },
      { id: "2", name: t("company.ReleaseMail") },
    ];
    const selectedContactType = ref(contactOptions[0].id);

    const notificationOptions = [
      { id: "true", name: t("company.notificationOn") },
      { id: "false", name: t("company.notificationOff") },
    ];
    const selectedNotification = ref(notificationOptions[0].id)

    const handleAction = async () => {
      const type: number = isTransitionFromCompanyList ? parseInt(selectedContactType.value) : props.contactType
      const sendEmail: boolean = isTransitionFromCompanyList ? JSON.parse(selectedNotification.value.toLocaleLowerCase()) : props.isSendEmail
      const res = await props.action(type, sendEmail);
      if (res == true) {
        emit("change-customer-contact-done");
      } else {
        emit("change-customer-contact-error");
      }
      closePopup(popupKey);
    };

    return {
      isTransitionFromCompanyList,
      contactTypeName,
      contactOptions,
      notificationOptions,
      selectedContactType,
      selectedNotification,
      handleAction,
      popupKey,
    };
  },
});

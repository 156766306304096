import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b46d48a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "border-dashes-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 0,
  class: "imprint-setting-area"
}
const _hoisted_5 = { class: "shape-setting" }
const _hoisted_6 = {
  key: 0,
  class: "shape-text"
}
const _hoisted_7 = {
  key: 1,
  class: "shape-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextButton = _resolveComponent("TextButton")!
  const _component_ToggleCheckbox = _resolveComponent("ToggleCheckbox")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('company.editConfirmButton'),
        enabled: _ctx.enableBtn,
        class: "confirm-button",
        onClick: _ctx.submit_company_logo
      }, null, 8, ["text", "enabled", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isStamp && !_ctx.isRound)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "square-avatar",
              src: _ctx.showTempLogo,
              alt: "Symbol"
            }, null, 8, _hoisted_2))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              class: "round-avatar",
              src: _ctx.showTempLogo,
              alt: "Symbol"
            }, null, 8, _hoisted_3)),
        _createElementVNode("input", {
          type: "file",
          ref: "image",
          accept: "image/png, image/jpeg",
          style: {"display":"none"},
          name: "logo",
          onChange: _cache[0] || (_cache[0] = (e) => _ctx.uploadImage(e))
        }, null, 544),
        _createVNode(_component_TextButton, {
          icon: require('icons/camera.svg'),
          text: _ctx.$t('member.logoCamera'),
          onOnClick: _ctx.uploadFile,
          class: "child"
        }, null, 8, ["icon", "text", "onOnClick"]),
        _createVNode(_component_TextButton, {
          icon: require('icons/delete-orange.svg'),
          text: _ctx.$t('member.deletelogo'),
          onOnClick: _ctx.deleteImage,
          class: "child child-delete"
        }, null, 8, ["icon", "text", "onOnClick"])
      ]),
      (_ctx.isStamp)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_ToggleCheckbox, {
                checked: _ctx.isRound,
                "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isRound) = $event)),
                onOnChange: _ctx.onChangeImprintType
              }, null, 8, ["checked", "onOnChange"]),
              (!_ctx.isRound)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.$t("common.square")), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t("common.circle")), 1))
            ]),
            _createVNode(_component_Dropdown, {
              options: _ctx.sizeOptions,
              modelValue: _ctx.selectedSize,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedSize) = $event)),
              "model-value": _ctx.selectedSize,
              "is-small-dropdown": "",
              "item-text": "name",
              "item-value": "id",
              onClick: _ctx.onChangeImprintSize
            }, null, 8, ["options", "modelValue", "model-value", "onClick"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import usePopupControl from "./usePopupControl";
import { AnonymousFunction } from "@/models/Function";
import PlanInfo from "@/models/PlanInfo";


const { deleteCompany } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

const { openPopup } = usePopupControl();

export const companyToDelete = computed(() => {
  return {
    id: "",
    title: "",
    message: "",
    isInfo:  false,
    action: AnonymousFunction,
  };
});

const useDeleteCompany = () => {
  const { t } = useI18n();
  const handleDeleteCompany = ({ id = "", name = "", planType = 0, primaryPaymentMethodInfo = "" }) => {
    if (
      (planType == PlanInfo.PlanType.LITE_PLAN || planType === PlanInfo.PlanType.STANDARD_PLAN) &&
      primaryPaymentMethodInfo != PlanInfo.PaymentMethod.NO_PAYMENT_METHOD
    ) {
      companyToDelete.value.isInfo = true;
      companyToDelete.value.title = t("company.deleteCompany");
      companyToDelete.value.message = t("company.cantDeleteCompanyUntilFinishPayment");
      openPopup("confirmAction");
    } else {
      companyToDelete.value.id = id;
      companyToDelete.value.isInfo = false;
      companyToDelete.value.title = t("company.deleteCompany");
      companyToDelete.value.action = deleteCompany;
      companyToDelete.value.message = t("company.deleteCompanyMessage", {
        name,
      });
      openPopup("confirmAction");
    }
  }
  return { handleDeleteCompany }
}

export default useDeleteCompany;

import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import BreadScrum from "@/components/atomics/BreadScrum.vue";
import { useRoute, useRouter } from "vue-router";
import { RepositoryFactory, CompanyRepository } from "@/lib/https";
import AfterLoginPage from "@/views/layouts/AfterLoginAdminPage.vue";
import ListItemEditInfo from "@/components/atomics/list-item/ListItemEditInfo.vue";
import { useI18n } from "vue-i18n";
import UpdateTextPopup from "@/components/popups/UpdateTextPopup.vue";
import UpdateNamePopup from "@/components/popups/UpdateNamePopup.vue";
import UpdateLogoPopup from "@/components/popups/UpdateLogoPopup.vue";
import UpdateReferralCompanyPopup from "@/components/popups/company/UpdateReferralCompanyPopup.vue";
import UpdateAddressPopup from "@/components/popups/company/UpdateAddressPopup.vue";
import usePopupControl from "@/lib/compositional-logic/usePopupControl";
import useNotificationControl from "@/lib/compositional-logic/useNotificationControl";
import { AnonymousFunction } from "@/models/Function";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import ChangePlanPopup from "@/components/popups/company/ChangePlanPopup.vue";
import ConfirmDeleteCompanyPopup from "@/components/popups/ConfirmPopup.vue";
import ChangeCustomerContactPopup from "@/components/popups/company/ChangeCustomerContactPopup.vue";
import { ContactType, getContactTypeName } from "@/locales/ja/company";
import useDeleteCompany, { companyToDelete } from "@/lib/compositional-logic/useDeleteCompany";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import PlanInfo from "@/models/PlanInfo";
import DowngradeToFreePlanPopup from "@/components/popups/company/DowngradeToFreePlanPopup.vue";

export default defineComponent({
  name: "CompanyDetail",
  components: {
    UpdateLogoPopup,
    UpdateAddressPopup,
    UpdateNamePopup,
    UpdateReferralCompanyPopup,
    BreadScrum,
    AfterLoginPage,
    ListItemEditInfo,
    UpdateTextPopup,
    FlatButton,
    ToggleCheckbox,
    ChangePlanPopup,
    ConfirmDeleteCompanyPopup,
    ChangeCustomerContactPopup,
    Dropdown,
    DowngradeToFreePlanPopup,
  },
  setup() {
    const { t } = useI18n();
    const { openNotification } = useNotificationControl();
    const { handleDeleteCompany } = useDeleteCompany();
    const route = useRoute();
    const router = useRouter();

    const links = [
      { text: t("company.companyInfo"), to: "company-info" },
      { text: t("common.statisticInfo"), to: "statistic" },
      { text: t("company.gmo"), to: "gmo" },
      { text: t("company.contractsTitle"), to: "contracts" },
      { text: t("company.memberList"), to: "member-list" },
      { text: t("company.userEvent"), to: "user-event" },
      { text: t("company.memo"), to: "memo" },
      { text: t("company.titleColumnSchedule"), to: "plan" },
    ];

    const trialMethod = PlanInfo.PaymentMethod.NO_PAYMENT_METHOD
    const invoiceMethod = PlanInfo.PaymentMethod.INVOICE_PAYMENT_METHOD

    const activeLink = ref(links[0].to);
    const onSwitchPlan = ref<AnonymousFunction>();
    const state = reactive({
      companyData: {
        id: "",
        name: "",
        logo: require("icons/default-company-avatar.svg"),
        postalCode: "",
        prefectureId: "",
        city: "",
        address: "",
        buildingRoom: "",
        phoneNumber: "",
        representativeLastName: "",
        representativeFirstName: "",
        referralCompanyId: "",
        referralPersonLastName: "",
        referralPersonFirstName: "",
        controlNumber: "",
        imprint: "",
        imprintType: 1,
        imprintSize: 21,
        planType: 1,
        primaryPaymentMethodInfo: "",
        createdBy: "",
        createdAt: "",
        fullAddress: "",
        prefectureName: "",
        referralCompanyName: "",
        contractNumber: 0,
        fileNumber: 0,
        completedContractNumber: 0,
        completedContractNumberThisMonth: 0,
        countCreatedFromTemplate: 0,
        countContractInformation: 0,
        countFreeItem: 0,
        countFreeText: 0,
        countTextLink: 0,
        folderNumber: 0,
        memberNumber: 0,
        groupNumber: 0,
        templateNumber: 0,
        contactNumber: 0,
        gmoId: "",
        canDelete: false,
        canUpdate: false,
        contactTypes: [] as Array<number>,
        oemPlans: [] as Array<any>,
        oemPlanOptionsDisplay: [] as Array<any>,
        planSelectedOption: { id: "", name: ""},
      },
      baseAvatarUrl: process.env.VUE_APP_API_CLOUD_URL,
      databreadScrumLinks: [] as { text: string; to: string }[],
      fieldToEdit: {
        name: "",
        value: "",
        title: "",
        label: "",
        action: AnonymousFunction,
      },
      nameToEdit: {
        lastName: "",
        firstName: "",
        lastValue: "",
        firstValue: "",
        title: "",
        action: AnonymousFunction,
      },
      logoToEdit: {
        name: "",
        imprintType: "",
        imprintSize: "",
        deletedImage: "",
        logo: "",
        title: "",
        isStamp: false,
        isLogo: false,
        action: AnonymousFunction,
      },
      customerContactToEdit: {
        contactType: -1,
        isSendEmail: false,
        action: AnonymousFunction,
      },
      downgradeToFreePlanEdit: {
        name: "",
        planName: "",
        action: AnonymousFunction,
      },
    });

    const { popupState, openPopup } = usePopupControl();

    const statisticRows = computed(() => [
      {
        title: t("company.contractNumber"),
        value: state.companyData.contractNumber,
      },
      {
        title: t("company.fileNumber"),
        value: state.companyData.fileNumber,
      },
      {
        title: t("company.completedContract"),
        value: state.companyData.completedContractNumber,
      },
      {
        title: t("company.completedContractThisMonth"),
        value: state.companyData.completedContractNumberThisMonth,
      },
      {
        title: t("company.countCreatedFromTemplate"),
        value: state.companyData.countCreatedFromTemplate,
      },
      {
        title: t("company.countContractInformation"),
        value: state.companyData.countContractInformation,
      },
      {
        title: t("company.countFreeItem"),
        value: state.companyData.countFreeItem,
      },
      {
        title: t("company.countFreeText"),
        value: state.companyData.countFreeText,
      },
      {
        title: t("company.countTextLink"),
        value: state.companyData.countTextLink,
      },
      {
        title: t("company.folderNumber"),
        value: state.companyData.folderNumber,
      },
      {
        title: t("company.userNumber"),
        value: state.companyData.memberNumber,
      },
      {
        title: t("company.groupNumber"),
        value: state.companyData.groupNumber,
      },
      {
        title: t("company.templateNumber"),
        value: state.companyData.templateNumber,
      },
      {
        title: t("company.contactNumber"),
        value: state.companyData.contactNumber,
      },
    ]);

    const monthlyReportRows  = computed(() => [
      {
        title: t("company.monthlyReportLabel"),
        value: " ",
        action: () => {
          router.push({
            name: "MonthlyReport",
            params: { id: state.companyData.id },
          });
        },
      },
    ]);

    const gmoRows = computed(() => [
      {
        title: t("company.gmoId"),
        value: state.companyData.id,
      },
      {
        title: t("company.gmoBillingStatus"),
        value: " ",
        action: () => {
          router.push({
            name: "GmoBillingStatus",
            params: { id: state.companyData.id },
          });
        },
      },
    ]);

    const contractsRows = computed(() => [
      {
        title: t("company.contractsLabel"),
        value: " ",
        action: () => {
          router.push({
            name: "Contracts",
            params: { id: state.companyData.id },
          });
        },
      },
    ]);

    const memberListRows = computed(() => [
      {
        title: t("company.memberListTitle"),
        value: " ",
        action: () => {
          router.push({
            name: "CompanyMember",
            params: { companyID: state.companyData.id },
          });
        },
      },
    ]);

    const userEventRows = computed(() => [
      {
        title: t("company.checkUserEventOfCompany"),
        value: " ",
        action: () => {
          router.push({
            name: "UserEvent",
            params: { companyID: state.companyData.id },
          });
        },
      },
    ]);

    const customerContactRows = computed(() => [
      {
        title: getContactTypeName(ContactType.CustomerSurvey),
        value: state.companyData.contactTypes !== undefined && state.companyData.contactTypes.includes(ContactType.CustomerSurvey),
        action: (value: boolean) => {
          state.customerContactToEdit.contactType = ContactType.CustomerSurvey;
          state.customerContactToEdit.isSendEmail = value;
          state.customerContactToEdit.action = handleChangeCustomerContact
          openPopup("changeCustomerContact")
        },
      },
    ]);

    const downgradeFreePlanRows = computed(() => [
      {
        title: t("company.downgradeToFreePlan"),
        value: " ",
        action: (name: string, planType: number) => {
          state.downgradeToFreePlanEdit.name = name;
          state.downgradeToFreePlanEdit.planName = PlanInfo.getPlanName(planType);
          state.downgradeToFreePlanEdit.action = handleDowngradeToFreePlan
          openPopup("downgradeToFreePlan")
        }
      },
    ]);

    const handleChangeCustomerContact = async (contactType: number, sendEmail: boolean) => {
      if (sendEmail) {
        await updateCustomerContact(state.companyData.id, contactType)
      } else {
        await deleteCustomerContact(state.companyData.id, contactType)
      }
    }

    const memoRows = computed(() => [
      {
        title: t("company.memoLabel"),
        value: " ",
        action: () => {
          router.push({
            name: "Memo",
            params: { companyID: state.companyData.id },
          });
        },
      },
    ]);

    const planRows = computed(() => [
      {
        title: t("company.changeTrialPlan"),
        value: " ",
        action: (value: boolean) => {
          openPopup("changePlanCompany");

          if (value) {
            onSwitchPlan.value = () =>
              switchToTrialOn(state.companyData.id);
          } else {
            onSwitchPlan.value = () => switchToTrialOff(state.companyData.id);
          }
        },
      },
    ]);

    const switchPlanRows = computed(() => [
      {
        title: t("company.titleSwitchPlan"),
        value: "",
      },
    ]);

    const infoRows = computed(() => [
      {
        title: t("company.titleSymbol"),
        value: state.companyData.logo,
        action: () => {
          state.logoToEdit.name = "logo";
          state.logoToEdit.title = t("company.titleSymbolEdit");
          state.logoToEdit.logo = state.companyData.logo
          ? state.baseAvatarUrl + state.companyData.logo + `?${Date.now()}`
          : require("icons/default-company-avatar.svg");
          state.logoToEdit.action = updateCompanyLogo;
          state.logoToEdit.isLogo = true;
          state.logoToEdit.isStamp = false;
          openPopup("updateLogo");
        },
        isLogo: true,
      },
      {
        title: t("company.titleCompanyName"),
        value: state.companyData.name,
        action: () => {
          state.fieldToEdit.name = "name";
          state.fieldToEdit.value = state.companyData.name;
          state.fieldToEdit.title = t("company.editCompanyName");
          state.fieldToEdit.label = t("company.editCompanyNameLabel");
          state.fieldToEdit.action = updateCompanyName;
          openPopup("updateText");
        },
      },
      {
        title: t("company.titlePosition"),
        value: state.companyData.fullAddress,
        action: () => {
          openPopup("updateAddress");
        },
      },
      {
        title: t("company.titlePhone"),
        value: state.companyData.phoneNumber,
        action: () => {
          state.fieldToEdit.name = "phoneNumber";
          state.fieldToEdit.value = state.companyData.phoneNumber;
          state.fieldToEdit.title = t("company.editPhoneNumber");
          state.fieldToEdit.label = t("company.editPhoneNumberLabel");
          state.fieldToEdit.action = updateCompanyPhoneNumber;
          openPopup("updateText");
        },
      },
      {
        title: t("company.titlePresent"),
        value: 
          state.companyData.representativeLastName ||
          state.companyData.representativeFirstName
            ? `${state.companyData.representativeLastName ?? ""} ${state.companyData.representativeFirstName ?? ""}`
            : "",
        action: () => {
          state.nameToEdit.firstName = "representativeFirstName";
          state.nameToEdit.lastName = "representativeLastName";
          state.nameToEdit.firstValue =
            state.companyData.representativeFirstName;
          state.nameToEdit.lastValue = state.companyData.representativeLastName;
          state.nameToEdit.title = t("company.editRepresentativeName");
          state.nameToEdit.action = updateRepresentativeName;
          openPopup("updateName");
        },
      },
      {
        title: t("company.titlePartnerName"),
        value: state.companyData.referralCompanyName,
        action: () => {
          openPopup("updateReferralCompany");
        },
      },
      {
        title: t("company.titleSellerName"),
        value:
          state.companyData.referralPersonFirstName &&
          state.companyData.referralPersonLastName
            ? `${state.companyData.referralPersonLastName} ${state.companyData.referralPersonFirstName}`
            : "",
        action: () => {
          state.nameToEdit.firstName = "referralPersonFirstName";
          state.nameToEdit.lastName = "referralPersonLastName";
          state.nameToEdit.firstValue =
            state.companyData.referralPersonFirstName;
          state.nameToEdit.lastValue = state.companyData.referralPersonLastName;
          state.nameToEdit.title = t("company.editReferralCompanyName");
          state.nameToEdit.action = updateReferralPersonName;
          openPopup("updateName");
        },
      },
      {
        title: t("company.titleClientPhone"),
        value: state.companyData.controlNumber,
        action: () => {
          state.fieldToEdit.name = "controlNumber";
          state.fieldToEdit.value = state.companyData.controlNumber;
          state.fieldToEdit.title = t("company.editControlNumber");
          state.fieldToEdit.label = t("company.editControlNumberLabel");
          state.fieldToEdit.action = updateCompanyControlNumber;
          openPopup("updateText");
        },
      },
      {
        title: t("company.titleStampImage"),
        value: state.companyData.imprint,
        action: () => {
          state.logoToEdit.name = "imprint";
          state.logoToEdit.imprintType = "imprintType";
          state.logoToEdit.imprintSize = "imprintSize";
          state.logoToEdit.deletedImage = "deletedImage";
          state.logoToEdit.title = t("company.titleStampImageEdit");
          state.logoToEdit.logo = state.companyData.imprint
            ? state.baseAvatarUrl + state.companyData.imprint + `?${Date.now()}`
            : "";
          state.logoToEdit.action = updateCompanyImprint;
          state.logoToEdit.isStamp = true;
          state.logoToEdit.isLogo = false;
          openPopup("updateLogo");
        },
        isStamp: true,
      },
    ]);

    const onDeleteCompany = async ({ id = "", name = "", planType = 0, primaryPaymentMethodInfo = "" }) => {
      handleDeleteCompany({ id, name, planType, primaryPaymentMethodInfo })
    };

    const onDeleteDone = (message: string) => {
      if (message) openNotification({ type: "error", text: message })
      else {
        router.push({ name: "Company" })
        openNotification()
      }
    };

    const scrollTo = async (elementID: string) => {
      const scrollElement = document.getElementById(elementID);
      if (!scrollElement) return;

      activeLink.value = elementID;
      const originalTop = scrollElement.getBoundingClientRect().top - 140;

      document
        .getElementsByClassName("page-container")[0]
        .scrollBy({ top: originalTop, behavior: "smooth" });
    };

    const companyID = route.params.companyID;
    const {
      getCompany,
      updateCompanyName,
      updateCompanyControlNumber,
      updateCompanyPhoneNumber,
      updateRepresentativeName,
      updateReferralPersonName,
      updateCompanyLogo,
      updateCompanyImprint,
      switchToTrialOff,
      switchToTrialOn,
      updateOemPlan,
      updateCustomerContact,
      deleteCustomerContact,
      turnOnInvoicePayment,
      turnOffInvoicePayment,
      downgradeToFreePlam,
    } = RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const getCompanyInfo = async () => {
      if (!companyID) return;

      const response = await getCompany(companyID as string);
      if (!response) return;

      state.companyData = response;
      state.databreadScrumLinks = [
        { text: t("company.titleListCompanies"), to: "/company" },
        {
          text: state.companyData.name,
          to: "/company/" + state.companyData.id,
        },
      ];

      if (PlanInfo.PlanType.OEM_TERMINATION_PLAN <= state.companyData.planType) {
        state.companyData.oemPlanOptionsDisplay = PlanInfo.getOemPlanOptionsByPlanType(state.companyData.planType, state.companyData.oemPlans);
        state.companyData.planSelectedOption = PlanInfo.getOemPlanOption(state.companyData.planType)
      }
    };

    const switchPlan = async (value: any) => {
      if (PlanInfo.isDigitalSignPaidPlan(state.companyData.planType)) {
        openNotification({ type: "error", text: t("company.errorWhenSwitchToOEMPlan") });
        return;
      }
      const isSuccess = await updateOemPlan(state.companyData.id, value) as any;
      if (isSuccess) {
        openNotification();
      } else {
        openNotification({ type: "error", text: t("company.errorWhenSwitchToOEMPlan") });
      }
      await getCompanyInfo();
    }

    const switchInvoicePaymentMethod = async () => {
      try {
        if (state.companyData.primaryPaymentMethodInfo != invoiceMethod) {
          await turnOnInvoicePayment(state.companyData.id)
        } else {
          await turnOffInvoicePayment(state.companyData.id)
        }
        openNotification();
        await getCompanyInfo();
      } catch (e: any) {
        openNotification({ type: "error", text: e.data.message });
      }
    }

    onMounted(getCompanyInfo);

    const editTextPopupInfo = computed(() => ({
      initialTextValue: state.fieldToEdit.value,
      title: state.fieldToEdit.title,
      label: state.fieldToEdit.label,
      placeholder: state.fieldToEdit.label,
      fieldName: state.fieldToEdit.name,
      action: (formData: FormData) =>
        state.fieldToEdit.action(formData, state.companyData.id),
    }));

    const editNamePopupInfo = computed(() => ({
      firstName: state.nameToEdit.firstValue,
      lastName: state.nameToEdit.lastValue,
      title: state.nameToEdit.title,
      action: async (lastName: string, firstName: string) => {
        const formData = new FormData();
        formData.append(state.nameToEdit.firstName, firstName);
        formData.append(state.nameToEdit.lastName, lastName);
        await state.nameToEdit.action(formData, state.companyData.id);
      },
    }));

    const editLogoPopupInfo = computed(() => ({
      logo: state.logoToEdit.logo,
      title: state.logoToEdit.title,
      companyId: state.companyData.id,
      isStamp: state.logoToEdit.isStamp,
      isLogo: state.logoToEdit.isLogo,
      imprintOptions: {
        imprintType: state.companyData.imprintType,
        imprintSize: (state.companyData.imprintSize || 21).toString(),
      },
      action: async (logo: string, type: boolean, size: string, deleted: boolean) => {
        const formData = new FormData();
        formData.append(state.logoToEdit.name, logo);
        formData.append(state.logoToEdit.imprintType, type ? "round" : "square");
        formData.append(state.logoToEdit.imprintSize, size);
        if (deleted) {
          formData.append(state.logoToEdit.deletedImage, deleted.toString());
        }
        await state.logoToEdit.action(formData, state.companyData.id);
      },
    }));

    const handleDowngradeToFreePlan = async () => {
      try {
        return await downgradeToFreePlam(state.companyData.id)
      } catch (e: any) {
        openNotification({ type: "error", text: e.data.message });
      }
    };

    return {
      ...toRefs(state),
      infoRows,
      statisticRows,
      monthlyReportRows,
      gmoRows,
      contractsRows,
      memberListRows,
      userEventRows,
      customerContactRows,
      memoRows,
      planRows,
      switchPlanRows,
      downgradeFreePlanRows: downgradeFreePlanRows,
      popupState,
      editTextPopupInfo,
      editNamePopupInfo,
      editLogoPopupInfo,
      onSwitchPlan,
      links,
      activeLink,
      companyToDelete,
      trialMethod,
      invoiceMethod,
      openNotification,
      handleDeleteCompany,
      getCompanyInfo,
      scrollTo,
      onDeleteCompany,
      onDeleteDone,
      openPopup,
      handleChangeCustomerContact,
      switchPlan,
      switchInvoicePaymentMethod,
      handleDowngradeToFreePlan,
    };
  },
});

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "popup-input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        "on-click": _ctx.updateText,
        text: _ctx.btnText,
        class: "w-full"
      }, null, 8, ["on-click", "text"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.label), 1),
      (_ctx.isNumber)
        ? (_openBlock(), _createBlock(_component_ValidationTextField, {
            key: 0,
            value: _ctx.textValue,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.textValue) = $event)),
            valueModifiers: { number: true },
            placeholder: _ctx.placeholder,
            rules: "required",
            message: _ctx.errorMessage,
            name: _ctx.fieldName,
            "suffix-value": _ctx.suffixValue,
            isMultiline: _ctx.isMultiline,
            maxLength: _ctx.maxLength
          }, null, 8, ["value", "placeholder", "message", "name", "suffix-value", "isMultiline", "maxLength"]))
        : (_openBlock(), _createBlock(_component_ValidationTextField, {
            key: 1,
            value: _ctx.textValue,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.textValue) = $event)),
            placeholder: _ctx.placeholder,
            rules: "required",
            message: _ctx.errorMessage,
            name: _ctx.fieldName,
            "suffix-value": _ctx.suffixValue,
            isMultiline: _ctx.isMultiline,
            maxLength: _ctx.maxLength
          }, null, 8, ["value", "placeholder", "message", "name", "suffix-value", "isMultiline", "maxLength"]))
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
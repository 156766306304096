
import {
  defineComponent,
  ref,
  computed,
  PropType,
  reactive,
  toRefs,
  watch,
} from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import ToggleCheckbox from "@/components/atomics/ToggleCheckbox.vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import { useI18n } from "vue-i18n";

type Action = (
  text: Blob,
  type: boolean,
  size: string,
  deleted: boolean
) => void;

export default defineComponent({
  name: "UpdateLogoPopup",
  components: {
    FlatButton,
    TextButton,
    Popup,
    ToggleCheckbox,
    Dropdown,
  },
  props: {
    logo: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
    isStamp: {
      type: Boolean,
      default: false,
    },
    isLogo: {
      type: Boolean,
      default: false,
    },
    imprintOptions: {
      type: Object,
      default: {} as any,
    },
  },
  emits: ["logo-updated"],
  setup(props, { emit }) {
    const state = reactive({
      logoFileTemp: "",
      companyLogo: props.logo,
      enableBtn: false,
      sizeOptions: [
        { id: "15", name: "15mm" },
        { id: "18", name: "18mm" },
        { id: "21", name: "21mm" },
        { id: "24", name: "24mm" },
      ],
      isStamp: props.isStamp,
      isLogo: props.isLogo,
      isRound: props.imprintOptions?.imprintType === 1,
      selectedSize: props.imprintOptions?.imprintSize || "21",
      deletedImage: false,
    });

    watch(
      () => props.imprintOptions,
      (val: any) => {
        state.selectedSize = val.imprintSize;
        state.isRound = val.imprintType === 1;
      },
      { deep: true }
    );

    const { t } = useI18n();

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateLogo";

    const handleEnableBtn = () => {
      state.enableBtn = true;
    };

    const showTempLogo = computed(() => {
      if (state.logoFileTemp) {
        handleEnableBtn();
        return URL.createObjectURL(state.logoFileTemp as any);
      }
      if (state.companyLogo) {
        return state.companyLogo;
      } else {
        if (state.isStamp) {
          if (state.isRound) {
            return require("images/stamp.svg");
          } else {
            return require("images/square-stamp.svg");
          }
        } else if (state.isLogo) {
          return require("icons/default-company-avatar.svg");
        } else {
          return require("icons/default-avatar.svg");
        }
      }
    });

    const image = ref<HTMLInputElement>();

    const uploadImage = (e: any) => {
      const file = e?.target.files[0];
      state.logoFileTemp = file;
    };

    const deleteImage = () => {
      state.logoFileTemp = "";
      state.companyLogo = "";
      if (props.logo && props.logo !== "default") {
        handleEnableBtn();
      } else {
        state.enableBtn = false;
      }
      state.deletedImage = true;
    };

    const onChangeImprintType = () => {
      handleEnableBtn();
    };

    const onChangeImprintSize = () => {
      handleEnableBtn();
    };

    const submit_company_logo = async function () {
      await props.action(
        state.logoFileTemp as any,
        state.isRound as any,
        state.selectedSize as any,
        state.deletedImage as any
      );
      emit("logo-updated");
      closePopup(popupKey);
    };

    const uploadFile = () => {
      const fileInput = image.value;
      fileInput?.click();
    };

    return {
      ...toRefs(state),
      showTempLogo,
      uploadImage,
      deleteImage,
      submit_company_logo,
      closePopup,
      popupKey,
      image,
      uploadFile,
      onChangeImprintType,
      onChangeImprintSize,
    };
  },
});


import { defineComponent, PropType } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";

type Action = () => Promise<unknown>;

export default defineComponent({
  name: "DowngradeToFreePlanPopup",
  components: {
    Popup,
    FlatButton,
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    planName: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      required: true,
    },
  },
  emits: ["downgrade-done", "downgrade-error"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "downgradeToFreePlan";

    const handleAction = async () => {
      const response = await props.action();
      if (response.status != 200) {
        emit("downgrade-error");
        return;
      }

      emit("downgrade-done");
      closePopup(popupKey);
    };

    return {
      handleAction,
      popupKey,
    };
  },
});

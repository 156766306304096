
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import {
  RepositoryFactory,
  CompanyRepository,
  OnboardingRepository,
} from "@/lib/https";

export default defineComponent({
  name: "UpdateAddressPopup",
  components: {
    FlatButton,
    Popup,
    ValidationTextField,
    Dropdown,
  },
  props: {
    companyId: { type: String, required: true },
    editPostalCode: { type: String, default: "" },
    editPrefectureId: { type: String, default: "" },
    editCity: { type: String, default: "" },
    editAddress: { type: String, default: "" },
    editBuildingRoom: { type: String, default: "" },
  },
  emits: ["onEdit"],
  setup(props, { emit }) {
    const state = reactive({
      infoAddress: {
        editPostalCode: props.editPostalCode ?? "",
        editPrefectureId: props.editPrefectureId ?? "",
        editCity: props.editCity ?? "",
        editAddress: props.editAddress ?? "",
        editBuildingRoom: props.editBuildingRoom ?? "",
      },
      provinceList: [] as Record<string, string>[],
    });

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateAddress";

    const { getProvinces } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );
    const { updateCompanyAddress } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const submit_company_address = async function () {
      const formData = new FormData();
      formData.append("postalCode", state.infoAddress.editPostalCode);
      formData.append("prefectureId", state.infoAddress.editPrefectureId);
      formData.append("city", state.infoAddress.editCity);
      formData.append("address", state.infoAddress.editAddress);
      formData.append("buildingRoom", state.infoAddress.editBuildingRoom);
      const response = await updateCompanyAddress(formData, props.companyId);
      if (response) {
        emit("onEdit", response);
        closePopup(popupKey);
      }
    };

    onMounted(async () => {
      const response = await getProvinces();
      if (response) {
        state.provinceList = response;
      }
    });

    return {
      ...toRefs(state),
      submit_company_address,
      closePopup,
      popupKey,
    };
  },
});

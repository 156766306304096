import { useForm, FormContext } from "vee-validate";

export default function useFormHandler<
  T extends Record<string, any>,
  F extends (...args: any) => Promise<any>
>(
  initialValues: T,
  onSubmit: F,
  type: "form" | "object" = "form"
): {
  form: FormContext<T>;
  formHandle: () => ReturnType<F>;
} {
  const form = useForm({
    initialValues,
  });
  const { values, meta, submitForm, errors } = form;

  const formHandle = async function () {
    await submitForm();

    if (!meta.value.valid) return Promise.reject(errors.value);

    let data;
    if (type == "form") {
      data = new FormData();
      for (const key in values) {
        data.append(key, values[key]);
      }
    } else data = values;
    return await onSubmit(data);
  } as any;

  return {
    form,
    formHandle,
  };
}

import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d9f81ca"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 0,
  class: "custom-options"
}
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input custom-select-wrapper", {
      'custom-select-wrapper-small': _ctx.isSmallDropdown,
      open: _ctx.isDropdownOpen,
    }]),
    ref: "dropdown",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDropdownOpen = !_ctx.isDropdownOpen))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["custom-select__trigger", { 'custom-select-small__trigger': _ctx.isSmallDropdown }])
    }, [
      _createTextVNode(_toDisplayString(_ctx.textToShow) + " ", 1),
      _createElementVNode("img", {
        class: "icon",
        src: require('icons/icon_dropdown.svg')
      }, null, 8, _hoisted_1)
    ], 2),
    (_ctx.isDropdownOpen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(["custom-option", { selected: index == _ctx.selectedIndex }]),
              onClick: ($event: any) => (_ctx.chooseItem(index))
            }, _toDisplayString(_ctx.getText(option)), 11, _hoisted_3))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e98f73d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        class: "message",
        innerHTML: _ctx.message
      }, null, 8, _hoisted_1),
      _createVNode(_component_FlatButton, {
        "on-click": _ctx.onAction,
        text: _ctx.isInfo ? _ctx.$t('common.complete') : _ctx.btnText || _ctx.$t('common.delete'),
        class: "confirm-btn mt-2"
      }, null, 8, ["on-click", "text"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
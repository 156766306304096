
import { computed, defineComponent, onMounted, ref } from "vue";

interface LinkProps {
  text: string;
  to: string;
}

export default defineComponent({
  name: "BreadScrum",
  props: {
    paths: { type: Array, default: [] as Array<LinkProps> },
    isRouterLink: { type: Boolean, default: true },
  },
  setup(props) {
    const links = ref([] as Array<LinkProps>);
    const linkLength = computed(() => props.paths.length);
    const splitCharacter = ref(">");

    onMounted(() => {
      links.value = props.paths as Array<LinkProps>;
    });

    return { links, linkLength, splitCharacter };
  },
});

import { computed, ref, ComputedRef, Ref } from "vue";
import useBreakpoint from "@/lib/compositional-logic/useBreakpoint";
import { tryOnMounted } from "@vueuse/core";
import { useMutationObserver } from "@vueuse/core";

export default function isOverflow(
  element: Ref<HTMLElement | null>
): ComputedRef<boolean> {
  const scrollHeight = ref(0);
  const clientHeight = ref(0);

  const getHeight = () => {
    if (!element.value) return;
    scrollHeight.value = element.value.scrollHeight;
    clientHeight.value = element.value.clientHeight;
  };

  tryOnMounted(getHeight);

  useBreakpoint(getHeight);

  useMutationObserver(element, getHeight, {
    childList: true,
    subtree: true,
  });

  return computed(() => scrollHeight.value > clientHeight.value);
}

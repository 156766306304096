
import { defineComponent, PropType, ref, watch } from "vue";
import { onClickOutside } from "@vueuse/core";

export default defineComponent({
  name: "Dropdown",
  props: {
    options: {
      type: Array as PropType<Array<string | number | Record<string, unknown>>>,
      default: () => [],
    },
    modelValue: {
      type: [String, Number, Object],
      default: "",
    },
    itemText: {
      type: String,
      default: "",
    },
    itemValue: {
      type: String,
      default: "",
    },
    placeHolder: { type: String, default: "" },
    isSmallDropdown: { type: Boolean, default: false },
  },
  emits: ["update:modelValue", "onSelectItem"],
  setup(props, { emit }) {
    const isDropdownOpen = ref(false);
    const dropdown = ref<HTMLElement>();
    onClickOutside(dropdown, () => (isDropdownOpen.value = false));

    const textToShow = ref(props.modelValue ? null : props.placeHolder);
    const selectedIndex = ref(props.placeHolder ? -1 : 0);

    const isObjectWithKey = (
      item: unknown,
      key: string
    ): item is Record<string, any> => {
      if (typeof item !== "object" || item === null) return false;
      return key in item;
    };

    const getText = (item: unknown) =>
      isObjectWithKey(item, props.itemText) ? item[props.itemText] : item;
    const getValue = (item: unknown) =>
      isObjectWithKey(item, props.itemValue) ? item[props.itemValue] : item;
    const getItemByValue = (item: string | number | Record<string, unknown>) =>
      isObjectWithKey(item, props.itemValue)
        ? item
        : props.options.find((option) => {
            if (isObjectWithKey(option, props.itemValue))
              return option[props.itemValue] == item;
            return option == item;
          });

    const chooseItem = (index: number) => {
      selectedIndex.value = index;

      const itemAtIndex = props.options[index];
      textToShow.value = getText(itemAtIndex);
      emit("update:modelValue", getValue(itemAtIndex));
      emit("onSelectItem", itemAtIndex);
    };

    watch(
      () => props.modelValue,
      (val) => {
        if (!val) return;
        // Only do this because for some reason, props.options can not be read in watch immediate
        setTimeout(() => {
          const item = getItemByValue(val);
          textToShow.value = getText(item);
        }, 100);
      },
      { immediate: true }
    );

    return {
      isDropdownOpen,
      dropdown,
      textToShow,
      selectedIndex,
      getText,
      chooseItem,
    };
  },
});

import { AxiosResponse } from "axios";

export const isAxiosResponse = (error: unknown): error is AxiosResponse => {
  if (error && typeof error == "object") if ("data" in error) return true;
  return false;
};

export const isFieldError = (
  error: unknown
): error is Record<string, string> => {
  if (error && typeof error == "object") return true;
  return false;
};

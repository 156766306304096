
import { defineComponent } from "vue";
import ItemInList from "@/components/atomics/list-item/ItemInList.vue";
import TextButton from "@/components/atomics/button/TextButton.vue";

export default defineComponent({
  name: "ListItemEditInfo",
  components: {
    ItemInList,
    TextButton,
  },
  props: {
    infoTitle: {
      type: String,
      default: "",
    },
    infoValue: {
      type: [String, Number],
      default: "",
    },
    disableAction: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-edit"],
  setup() {
    return {};
  },
});

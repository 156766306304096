import { ref, Ref } from "vue";
import { useDebounceFn, useEventListener } from "@vueuse/core";

type OptionS = {
  debounceTime?: number;
};

const useBreakpoint = (
  resizeCallBack?: (e: UIEvent) => void,
  options: OptionS = {}
): {
  isMobile: Ref<boolean>;
} => {
  const { debounceTime = 0 } = options;

  const isMobile = ref(window.innerWidth < 960);

  const onResize = (e: UIEvent) => {
    isMobile.value = window.innerWidth < 960;
    if (resizeCallBack) resizeCallBack(e);
  };

  const debouncedonResize = useDebounceFn(onResize, debounceTime);

  useEventListener(window, "resize", debouncedonResize, { passive: true });

  return {
    isMobile,
  };
};

export default useBreakpoint;


import { defineComponent, PropType, ref } from "vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";

import closeIcon from "@/assets/icons/close.svg";
import useOverFlow from "@/lib/compositional-logic/useOverFlow";

const __default__ = defineComponent({
  name: "Popup",
  props: {
    title: { type: String, required: true },
    closeButton: { type: Boolean, default: true },
    popupKey: {
      type: String as PropType<PopupKey>,
      default: "",
    },
  },
  emits: ["onClickOutsidePopup", "onHide"],
  setup(props, { emit }) {
    const popupBody = ref<HTMLElement | null>(null);
    const isOverflowed = useOverFlow(popupBody);

    const { closePopup, popupState } = usePopupControl();

    const clickOutPopup = (e: MouseEvent) => {
      if ((e.target as HTMLElement).className === "popup-wrapper") {
        emit("onClickOutsidePopup");
        if (props.popupKey) closePopup(props.popupKey);
      }
    };

    const hidePopup = () => {
      emit("onHide");
      if (props.popupKey) closePopup(props.popupKey);
    };

    return {
      closeIcon,
      clickOutPopup,
      hidePopup,
      popupState,
      popupBody,
      isOverflowed,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "585ed806": (_ctx.isOverflowed ? '20px' : '12px'),
  "7567ea1c": (_ctx.isOverflowed ? 0 : '-12px')
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__
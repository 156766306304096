
import { defineComponent } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ConfirmPopup",
  components: {
    Popup,
    FlatButton,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
      required: true,
    },
    btnText: {
      type: String,
      default: "",
    },
    isInfo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["on-done"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "confirmAction";

    const onAction = async () => {
      if (props.isInfo) {
        closePopup(popupKey);
        return;
      }

      const result = await props.action(props.id);
      if (result) {
        result.data.message === t("company.cantDeleteCompanyUntilFinishPayment") 
          ? emit("on-done", result.data.message) 
          : emit("on-done");
      }
      closePopup(popupKey);
    };

    return {
      popupKey,
      onAction,
    };
  },
});

import company from "@/locales/ja/company";

enum PlanType {
  FREE_PLAN              = 0,
  LITE_PLAN              = 2,
  STANDARD_PLAN          = 1,
  OEM_TERMINATION_PLAN   = 100,
  OEM_PLAN1              = 101,
  OEM_LITE_PLAN          = 102,
  OEM_STANDARD_PLAN      = 103,
  OEM_PLAN2              = 111,
  OEM_FLAT_FREE          = 200,
  OEM_FLAT_LITE_PLAN     = 201,
  OEM_FLAT_STANDARD_PLAN = 202,
}

enum PaymentMethod {
  NO_PAYMENT_METHOD = "クレジットカードなし",
  OEM_PAYMENT_METHOD = "OEM Payment",
  INVOICE_PAYMENT_METHOD = "Invoice Payment"
}

const oemPlanOptions = [
  { id: "100", name: company.planOemFree },
  { id: "101", name: company.planOem1 },
  { id: "111", name: company.planOem2 },
  { id: "102", name: company.planOemLite },
  { id: "103", name: company.planOemStandard },
  { id: "200", name: company.planOemFlatFree },
  { id: "201", name: company.planOemFlatLite },
  { id: "202", name: company.planOemFlatStandard },
];

const getPlanName = (planType: number): string => {
  switch (planType){
    case PlanType.FREE_PLAN:
      return company.planFree;
    case PlanType.STANDARD_PLAN:
      return company.planStandard;
      case PlanType.LITE_PLAN:
      return company.planLite;
    case PlanType.OEM_TERMINATION_PLAN:
      return company.planOemFree;
    case PlanType.OEM_PLAN1:
      return company.planOem1;
    case PlanType.OEM_LITE_PLAN:
      return company.planOemLite;
    case PlanType.OEM_STANDARD_PLAN:
      return company.planOemStandard;
    case PlanType.OEM_PLAN2:
      return company.planOem2;
    case PlanType.OEM_FLAT_FREE:
      return company.planOemFlatFree;
    case PlanType.OEM_FLAT_LITE_PLAN:
      return company.planOemFlatLite;
    case PlanType.OEM_FLAT_STANDARD_PLAN:
      return company.planOemFlatStandard;
    default:
      return "";
  }
}

const getOemPlanOption = (planType: number): any => {
  switch (planType){
    case PlanType.OEM_PLAN1:
      return oemPlanOptions[1];
    case PlanType.OEM_PLAN2:
      return oemPlanOptions[2];
    case PlanType.OEM_LITE_PLAN:
      return oemPlanOptions[3];
    case PlanType.OEM_STANDARD_PLAN:
      return oemPlanOptions[4];
    case PlanType.OEM_FLAT_FREE:
      return oemPlanOptions[5];
    case PlanType.OEM_FLAT_LITE_PLAN:
      return oemPlanOptions[6];    
    case PlanType.OEM_FLAT_STANDARD_PLAN:
      return oemPlanOptions[7];
    default:
      return oemPlanOptions[0];
  }
}

const getOemPlanOptionsByPlanType = (planType: number, plans: number[]): any[] => {
  const list = []

  if (!plans) {
    return []
  }

  if (PlanType.OEM_TERMINATION_PLAN < planType && planType < PlanType.OEM_FLAT_FREE) {
    list.push(getOemPlanOption(PlanType.OEM_TERMINATION_PLAN))
  } else if (planType > PlanType.OEM_FLAT_FREE) {
    list.push(getOemPlanOption(PlanType.OEM_FLAT_FREE))
  } else {
    for (let i = 0; i < plans.length; i++) {
      list.push(getOemPlanOption(plans[i]))
    }
  }
  return list
}

const isDigitalSignPaidPlan = (planType:number): boolean => {
	return planType == PlanType.LITE_PLAN || planType == PlanType.STANDARD_PLAN
}

export default {
  PlanType,
  PaymentMethod,
  oemPlanOptions,
  getPlanName,
  getOemPlanOption,
  getOemPlanOptionsByPlanType,
  isDigitalSignPaidPlan,
};


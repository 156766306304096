import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "popup-input-label" }
const _hoisted_2 = { class: "popup-input-label mt-2" }
const _hoisted_3 = { class: "popup-input-label mt-2" }
const _hoisted_4 = { class: "popup-input-label mt-2" }
const _hoisted_5 = { class: "popup-input-label mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('company.editAddress'),
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('company.editConfirmButton'),
        class: "w-full",
        onClick: _ctx.submit_company_address
      }, null, 8, ["text", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("company.editPostalCodeLabel")), 1),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.infoAddress.editPostalCode,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.infoAddress.editPostalCode) = $event)),
        placeholder: _ctx.$t('company.editPostalCodeLabel'),
        name: "postalCode",
        rules: "required",
        message: {
        error_required: _ctx.$t('errors.required'),
      }
      }, null, 8, ["value", "placeholder", "message"]),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("company.editPrefectureLabel")), 1),
      _createVNode(_component_Dropdown, {
        options: _ctx.provinceList,
        modelValue: _ctx.infoAddress.editPrefectureId,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.infoAddress.editPrefectureId) = $event)),
        "item-text": "name",
        "item-value": "id"
      }, null, 8, ["options", "modelValue"]),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("company.editCityLabel")), 1),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.infoAddress.editCity,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.infoAddress.editCity) = $event)),
        placeholder: _ctx.$t('company.editCityLabel'),
        name: "city",
        rules: "required",
        message: {
        error_required: _ctx.$t('errors.required'),
      }
      }, null, 8, ["value", "placeholder", "message"]),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("company.editAddressLabel")), 1),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.infoAddress.editAddress,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.infoAddress.editAddress) = $event)),
        placeholder: _ctx.$t('company.editAddressLabel'),
        name: "address",
        rules: "required",
        message: {
        error_required: _ctx.$t('errors.required'),
      }
      }, null, 8, ["value", "placeholder", "message"]),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t("company.editBuildingRoomLabel")), 1),
      _createVNode(_component_ValidationTextField, {
        value: _ctx.infoAddress.editBuildingRoom,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.infoAddress.editBuildingRoom) = $event)),
        placeholder: _ctx.$t('company.editBuildingRoomLabel'),
        name: "buildingRoom",
        rules: "required",
        message: {
        error_required: _ctx.$t('errors.required'),
      }
      }, null, 8, ["value", "placeholder", "message"])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
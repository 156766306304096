
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FlatButton",
  emits: ["onClick"],
  props: {
    text: { type: String, required: true },
    enabled: { type: Boolean, default: true },
    icon: { type: String, default: "" },
    onClick: { type: Function, default: null },
  },
  setup(props, { emit }) {
    const isLoading = ref(false);

    const click = async () => {
      if (props.onClick) {
        isLoading.value = true;
        await props.onClick();
        isLoading.value = false;
      } else {
        emit("onClick", null);
      }
    };

    return {
      click,
      isLoading,
    };
  },
});


import { defineComponent, onMounted, reactive, toRefs } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import Dropdown from "@/components/atomics/dropdown/BaseDropdown.vue";
import {
  RepositoryFactory,
  CompanyRepository,
  OnboardingRepository,
} from "@/lib/https";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";

export default defineComponent({
  name: "UpdateReferralCompanyPopup",
  components: {
    FlatButton,
    Popup,
    Dropdown,
  },
  props: {
    companyId: { type: String, required: true },
    referralCompanyId: { type: String, default: "" },
    title: { type: String, required: true },
    labelPopup: { type: String, required: true },
  },
  emits: ["onEdit"],
  setup(props, { emit }) {
    const state = reactive({
      editValueId: props.referralCompanyId,
      list_referral_company: [] as Record<string, string>[],
    });

    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateReferralCompany";

    const { getPreferences } =
      RepositoryFactory.getRepository<OnboardingRepository>(
        OnboardingRepository
      );
    const { updateReferralCompanyName } =
      RepositoryFactory.getRepository<CompanyRepository>(CompanyRepository);

    const submit_referral_company_name = async function () {
      const formData = new FormData();
      formData.append("referralCompanyId", state.editValueId);
      const response = await updateReferralCompanyName(
        formData,
        props.companyId
      );
      if (response) {
        const referralCompany = state.list_referral_company.find(
          (company) => company.id === response.referralCompanyId
        );
        if (referralCompany) {
          emit("onEdit");
          closePopup(popupKey);
        }
      }
    };

    onMounted(async () => {
      const response = await getPreferences();
      if (response) {
        state.list_referral_company = response;
      }
    });

    return {
      ...toRefs(state),
      submit_referral_company_name,
      closePopup,
      popupKey,
    };
  },
});


import { defineComponent, PropType } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";

type Action = () => Promise<unknown>;

export default defineComponent({
  name: "ChangePlanPopup",
  components: {
    Popup,
    FlatButton,
  },
  props: {
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
    type: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: "",
    },
  },
  emits: ["change-plane-done", "change-plane-error"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "changePlanCompany";

    const handleAction = async () => {
      const response = await props.action();
      if (!response) {
        emit("change-plane-error");
        return;
      }

      emit("change-plane-done");
      closePopup(popupKey);
    };

    return {
      handleAction,
      popupKey,
    };
  },
});

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-663756e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-popup" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "w-full mt-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.$t('company.titlePopupSwitchPlan'),
    "popup-key": _ctx.popupKey
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", {
          innerHTML: _ctx.$t('company.titleNotificationConfirmSwitch', { type: _ctx.type, name: _ctx.name }),
          class: "message"
        }, null, 8, _hoisted_2),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FlatButton, {
            "on-click": _ctx.handleAction,
            text: _ctx.$t('company.editConfirmButton'),
            class: "mt-5 confirm-button"
          }, null, 8, ["on-click", "text"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}
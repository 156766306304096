
import { defineComponent, ref, PropType } from "vue";
import Popup from "@/components/popups/PopupBase.vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";
import usePopupControl, {
  PopupKey,
} from "@/lib/compositional-logic/usePopupControl";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { useI18n } from "vue-i18n";
import { isAxiosResponse, isFieldError } from "@/models/Error";

type Action = (text: string) => any;

export default defineComponent({
  name: "UpdateTextPopup",
  components: {
    Popup,
    FlatButton,
    ValidationTextField,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    initialTextValue: {
      type: [String, Number],
      default: "",
    },
    fieldName: {
      type: String,
      default: "",
    },
    btnText: {
      type: String,
      default: "変更する",
    },
    action: {
      type: Function as PropType<Action>,
      required: true,
    },
    payloadType: {
      type: String as PropType<"form" | "object">,
      default: "form",
    },
    isNumber: {
      type: Boolean,
      default: false,
    },
    suffixValue: {
      type: String,
      default: "",
    },
    isMultiline: {
      type: Boolean,
      default: false,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
  },
  emits: ["onUpdateTextDone"],
  setup(props, { emit }) {
    const { closePopup } = usePopupControl();
    const popupKey: PopupKey = "updateText";
    const { t } = useI18n();

    const errorMessage: Record<string, string> = {
      error_required: t("errors.required"),
    };

    const textValue = ref(props.initialTextValue);

    const { formHandle, form } = useFormHandler(
      { [props.fieldName]: textValue.value },
      props.action,
      props.payloadType
    );

    const updateText = async () => {
      try {
        await formHandle();
      } catch (error) {
        if (isAxiosResponse(error))
          form.setErrors({ [props.fieldName]: error.data.message });
        else if (isFieldError(error))
          form.setErrors({
            [props.fieldName]: errorMessage[error[props.fieldName]],
          });
        return;
      }

      emit("onUpdateTextDone");
      closePopup(popupKey);
    };

    return {
      textValue,
      popupKey,
      updateText,
      errorMessage,
      form,
    };
  },
});

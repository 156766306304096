import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7b5ed4a4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "name-fields" }
const _hoisted_2 = { class: "half" }
const _hoisted_3 = { class: "popup-input-label" }
const _hoisted_4 = { class: "half" }
const _hoisted_5 = { class: "popup-input-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidationTextField = _resolveComponent("ValidationTextField")!
  const _component_FlatButton = _resolveComponent("FlatButton")!
  const _component_Popup = _resolveComponent("Popup")!

  return (_openBlock(), _createBlock(_component_Popup, {
    title: _ctx.title,
    "popup-key": _ctx.popupKey
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FlatButton, {
        onOnClick: _ctx.updateText,
        text: _ctx.btnText,
        class: "w-full"
      }, null, 8, ["onOnClick", "text"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("common.firstNameHint")), 1),
          _createVNode(_component_ValidationTextField, {
            value: _ctx.localLastName,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localLastName) = $event)),
            placeholder: _ctx.$t('common.firstNameHint'),
            rules: "required",
            message: {
            error_required: _ctx.$t('errors.required'),
          }
          }, null, 8, ["value", "placeholder", "message"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("common.lastNameHint")), 1),
          _createVNode(_component_ValidationTextField, {
            value: _ctx.localFirstName,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localFirstName) = $event)),
            placeholder: _ctx.$t('common.lastNameHint'),
            rules: "required",
            message: {
            error_required: _ctx.$t('errors.required'),
          }
          }, null, 8, ["value", "placeholder", "message"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["title", "popup-key"]))
}